<template>
	<div>
		<!-- 이미지 업로드 -->
		<input type="file" class="inputFile hide" multiple @change="handleFileUpload" accept="image/*"/>

		<!-- 업로드한 이미지 리스트 -->
		<div class="dropZone image-container" @click="inputFile.click()">
			<div
				v-for="(image, idx) in previewList"
				:key="idx"
				class="image-wrapper"
				:class="{ 'dragging': dragIdx === idx }"
				draggable="true"
				@dragstart="dragStart(idx)"
				@dragover.prevent="dragOver(idx)"
				@drop="drop"
			>
				<img :src="image.preview" class="image-preview"/>
				<b-button
					class="absolute top-0 right-0"
					@click="e => { deleteFile( idx, e ) }"
					variant="no"
				>
					<b-icon font-scale="1" icon="x"/>
				</b-button>
			</div>
		</div>
	</div>
</template>

<script>
import { uploadFile } from "libs/axios";

let ths
export default {
	props: { cName: { default: 'multiImage' }, useS3: { default: true }, s3Key: { default: 'mainImage' }, s3Data: { default: '' } },
	data() {
		return {
			dropZone: null,
			inputFile: null,
			imgList: [], //업로드한 이미지 경로를 저장
			previewList: [], //드래그 중 보여줄 리스트
			dragIdx: null, //드래그 시작된 이미지의 인덱스
		}
	},
	methods: {
		handleFileUpload( e ) {
			this.uploadFiles( e.target.files )
		},
		dragStart( idx ) {
			this.dragIdx = idx // 드래그 시작 시 인덱스를 저장
		},
		dragOver( idx ) {
			// 드래그 중 미리보기를 업데이트
			this.updatepreviewList( idx )
		},
		drop() {
			// 드래그된 요소를 최종적으로 확정
			this.imgList = [ ...this.previewList ]
			this.dragIdx = null // 드래그 상태 초기화
			this.updatepreviewList()
		},
		updatepreviewList( dropIdx = null ) {
			// 기본적으로 원래 이미지를 미리보기로 설정
			this.previewList = [ ...this.imgList ]

			// 드래그 중일 때 순서를 업데이트
			if( dropIdx !== null && this.dragIdx !== null ) {
				const draggedImage = this.previewList[ this.dragIdx ]
				this.previewList.splice( this.dragIdx, 1 ) // 드래그된 이미지를 제거
				this.previewList.splice( dropIdx, 0, draggedImage ) // 드롭 위치에 삽입
			}
		},
		uploadFiles( files ) {
			for( let file of files ) {
				if( !file.type.startsWith( 'image/' ) )
					return alert.w( '이미지 형식이 아닙니다' )

				if( this.useS3 ) {
					if( file.size >= 1024 * 1024 * 10 )
						alert.w( 10 + 'MB 이하 이미지만 업로드 가능합니다.' )
					else {
						uploadFile( file, this.s3Key, undefined, undefined, this.s3Data ).then( url =>
							this.uploadFromUrl( `${ s3Url }${ /\/.*/.exec( url )[ 0 ] }` )
						)
					}
				}
				else {
					const reader = new FileReader()
					reader.onload = ( e ) => {
						this.imgList.push( { file, preview: e.target.result } ) // 이미지 데이터 URL 저장
						this.updatepreviewList() // previewList 업데이트
					}
					reader.readAsDataURL( file ) // 파일을 Data URL로 변환
				}
			}
		},
		deleteFile( idx, e ) {
			if( e ) {
				e.preventDefault()
				e.stopPropagation()
			}
			ths.imgList.splice( idx, 1 )
			ths.previewList.splice( idx, 1 )
		},
		uploadFromUrl( url ) {
			this.imgList.push( { url, preview: url } )
			this.updatepreviewList()
		},
		getImgList() {
			return this.imgList.map( v => v.url )
		}
	},
	mounted() {
		ths = this
		this.inputFile = this.$el.getElementsByClassName( 'inputFile' )[ 0 ]
		this.dropZone = this.$el.getElementsByClassName( 'dropZone' )[ 0 ]
		this.dropZone.ondragover = function( e ) {
			e.preventDefault() //안 하면 ondrop 이벤트가 발생하지 않음
		}
		this.dropZone.ondrop = function( e ) {
			e.preventDefault() //안 하면 브라우저에서 파일 띄움

			if( e?.target?.className?.indexOf( 'dropZone' ) == -1 ) return
			//이미 올라간 이미지에서 드래그&드롭으로 위치 바꾼 것

			ths.uploadFiles( e.dataTransfer?.files )
		}
		this.updatepreviewList() // 컴포넌트 초기화 시 미리보기 초기화
	},

}


</script>

<style>
.image-container {
	display: flex;
	flex-wrap: wrap;
	gap: 10px;
	margin-top: 20px;
}

.image-wrapper {
	width: 120px;
	height: 120px;
	display: flex;
	justify-content: center;
	align-items: center;
	background-color: #f9f9f9;
	cursor: grab;
	position: relative;
}

.image-wrapper:active {
	cursor: grabbing;
}

.image-preview {
	max-width: 100%;
	max-height: 100%;
}

.dropZone {
	min-height: 120px;
	border: 2px dashed var(--gray);
	border-radius: 10px;
	cursor: pointer;
}


</style>
