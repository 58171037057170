<template>
	<div id="app">
	</div>
</template>
<script>
let ths
export default {
	components: { },
	data() {
		return {
		}
	},
	methods: {
	},
	mounted() {
		ths = this
	},
}
</script>

<style>
</style>
